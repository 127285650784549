<template>
  <footer id="main-footer" class="tr-green-bg">
    <section class="tr-container footer-container">
      <div class="d-flex justify-content-between">
        <a href="/">
          <img alt="logo trekio white" src="../assets/svg/trekio-logo-white.svg" class="tr-small-brand">
        </a>
        <div class="socials">
          <a href="https://www.facebook.com/Trekiocom-119148256668570" id="fb" class="tr-top-nav-items__item" target="_blank"><img alt="FB icon" class="menu-icon" src="../assets/svg/black-facebook.svg"></a>
          <a href="https://www.instagram.com/_trekio_/" class="tr-top-nav-items__item" target="_blank"><img alt="FB icon" class="menu-icon" src="../assets/svg/black-instagram.svg"></a>
        </div>
      </div>
      <div class="tr-row">
        <div class="tr-col-12 tr-col-6--lg">
          <h2 class="tr__h2 tr-text--bold">{{ $t("general.footerText") }}</h2>
        </div>
        <div class="tr-col-12 tr-col-6--lg">
          <img class="tr-foto" alt="dívka" src="../assets/footer.png">
        </div>
      </div>
      <p><router-link to="/termsOfUse">{{ $t("general.termsOfUse")}}</router-link></p>
      <p><router-link to="/principlesDataProcessing">{{ $t("general.principlesDataProcessing") }}</router-link></p>
      <p class="tr__p"><span class="copyright">2000 - 2023 © Trekio • All rights reserved</span>&nbsp;•&nbsp;<span @click="showCookies">Cookies</span>&nbsp;•&nbsp;<router-link to="/adminLogin">{{ $t("general.signInAsAdmin") }}</router-link></p>
    </section>
  </footer>
</template>

<script>
    export default {
        name: "Footer",

        methods: {
          showCookies() {
            // eslint-disable-next-line
            Cookiebot.show()
          }
        }
    }

</script>

<style scoped lang="scss">
  @import '@/scss/variables';

  #main-footer {
    text-align: left;
  }

   .socials {
      display: flex;
      gap: 30px;
    }

  span, a {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 1200px) {

    .footer-container {
      max-width: 1160px;
    }

  }

  .copyright {
    color: $tr-gray;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }

</style>
