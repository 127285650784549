import { apolloClient } from "@/api/apolloClient";
import {SEND_CONTACT_EMAIL} from "@/api/graphql/mutation/ContactEmailMutation"

export default {
    sendContactEmail: function(form, recaptchaToken){
        const {name, email, msgBody } = form
        console.log(name, email, msgBody)
            return apolloClient.mutate({
                mutation: SEND_CONTACT_EMAIL,
                variables: {
                    name,
                    email,
                    msgBody,
                    recaptchaToken
                },
            }).then((result) => {
                console.log("Email sent!: ", result);
                return result;
            });
    },
}
