
const travelTipInfo = {
    tipTitle: `Jak se tvůj itinerář bude jmenovat?\nNázev je první informace o výletu. Musí zaujmout i informovat. Půjde se na vandr nebo do lázní? A kam to vlastně bude?`,
    tipShortInfo: `Podnadpis se zobrazuje po rozkliknutí kartičky výletu, jako doplněk nadpisu.\nZachyť pár slovy atmosféru nebo doplň info v nadpisu.`,
    tipPerex: `Perex jsou ty první dva tučné řádky v popisu výletu.`,
    tipDescription: `Stručný popis výletu jako celku.`,
    tipPracticalInfo: `Sem uveď praktické informace, které cestovatelům pomůžou v rozhodnutí, zda na výlet vyrazit, a potom v jeho plánování. Řekni jim, kde si mají hledat ubytování, jestli na výlet potřebují nějaké speciální znalosti nebo vybavení, sdílej odkazy užitečné před cestou atd.`,
    tipHashtags: `Jak bys svůj výlet otagoval/a na sociálních sítích?\nČesky, v cizích jazycích nebo mix.`,
    tipTheme: `Vyber možnost, která nejlépe odpovídá.\nVýlety lze podle tématu filtrovat, téma se také zobrazuje přímo na kartičce výletu.`,
    tipVideoDescription: `Přidávej pouze video, na které máš licenci. Sem napiš jeho popisek: o co jde nebo proč patří k tvému výletu.`,
    tipVideoUrl: `Přidávej pouze video, na které máš licenci. Sem na něj můžeš dát odkaz.`,
    tipPicture: `Přidávej pouze fotku, na kterou máš licenci! To znamená, že jde o tebou vyfocenou fotku bez identifikovatelných lidí nebo jsi licenci získal/a přímo od autora a všech modelů. Může tomu odpovídat například obrázek z fotobanky, tam si ale pořádně prostuduj a dodržuj všechna pravidla a podmínky.\nTato fotka bude úvodní fotkou výletu, zobrazí se na kartičce výletu a v jeho detailu to bude ta hlavní fotka, co se zobrazuje jako pozadí nadpisu.`,

    dayName: `Vymysli výstižný název pro tento den. Pokud si nevíš rady, zamysli se, co se bude dělat a kde.`,
    dayDescription: `Stručně popiš, co se během dne bude dělat. Nezacházej do detailů, na to jsou jednotlivé zastávky.`,
    dayPicture: `Vyber hlavní fotku dne z fotek, které máš přiřazené k jednotlivým zastávkám. Fotka dne se bude zobrazovat v přehledu dnů výletu v mobilní aplikaci Trekio. Pokud vybranou fotku od zastávky odstraníš, odstraní se i jako hlavní fotka dne.`,

    itemLatitude: `Automaticky se vyplní vybráním z mapy nebo můžeš zadat hodnotu formou desetinného čísla. Kladná hodnota odpovídá severní šířce (N), záporná jižní šířce (S).`,
    itemLongitude: `Automaticky se vyplní vybráním z mapy nebo můžeš zadat hodnotu formou desetinného čísla. Kladná hodnota odpovídá východní délce (E), záporná západní délce (W).`,
    itemCountries: {
        default: `Vyber zemi, kde se zatávka nachází a kudy vede cesta z předchozí zastávky nebo výchozího bodu. Můžeš vybrat více zemí.`,
        start: `Vyber zemi, kde se výchozí bod dnešní trasy nachází.`,
        finish: `Vyber zemi, kde se cílový bod dnešní trasy nachází a kudy vede cesta z předchozí zastávky. Můžeš vybrat více zemí.`,
    },
    itemTransferType: `Jakým způsobem se cestovatelé na zastávku dostanou z předchozí zastávky nebo výchozího bodu? Pokud musí cestou přestoupit, můžeš přidat další dopravu (max. 5).`,
    itemTransferLength: `Vzdálenost pro tento typ dopravy. (Pokud se přestupuje, rozděl délku celé cesty na zastávku odpovídajícím způsobem mezi jednotlivé dopravní prostředky.)\nMůžeš zadávat celá nebo desetinná čísla.`,
    itemTitle: {
        default: `Název místa (př. Špilberk) nebo trasy (př. Jelenka-Sněžka)`,
        start: `Název výchozího bodu dnešní trasy.`,
        finish: `Název cíle dnešní trasy nebo jejího posledního úseku.`,
    },
    itemDescription: {
        default: `Popiš cestu k zastávce i místo samotné. Čím je zajímavé, proč by se tu měl člověk zastavit, co tu uvidí nebo zažije?`,
        start: `Pokud je výchozí bod zajímavým místem, tady ho můžeš popsat.`,
        finish: `Popiš cestu do cílového bodu i místo samotné, pokud je samo o sobě zajímavé.`, 
    },
    itemPracticalInfo: {
        default: `Sem patří praktické informace jako odkaz na stránku s cenami vstupného a otevíracími hodinami. Pokud pro danou zastávku člověk potřebuje speciální vybavení nebo znalosti, taky to tu uveď.\nKdyž do tohoto pole dáš odkaz, zobrazí se jako klikatelná URL.`,
        start: `Sem patří praktické informace, např. kde nejlépe zaparkovat a kolik to stojí, nebo speciální vybavení pro absolvování úvodní zastávky, pokud je potřeba.\nKdyž do tohoto pole dáš odkaz, zobrazí se jako klikatelná URL.`,
    },
    // itemDifficulty: {
    //     default: `Vyber odpovídající náročnost zastávky a cesty na ní podle vysvětlivek zde (odkaz na stránku s vysvětlivkami náročnosti jak pro tvořiče výletů tak pro návštěvníky stránek - obsah viz tab Obtiznost).`,
    //     start: `Pokud je výchozí bod zároveň zajímavým místem, vyber, jak náročné je absolvování takové zastávky.`,
    //     finish: `Vyber odpovídající náročnost cílového bodu a cesty na něj podle vysvětlivek zde.`,
    // },
    itemDuration: `Kolik času zabere absolvovat tuto zastávku? (Cesta se do tohoto času nepočítá.)`,
    itemPictures: `Přidávej pouze fotku, na kterou máš licenci! To znamená, že jde o tebou vyfocenou fotku bez identifikovatelných lidí nebo jsi licenci získal/a přímo od autora a všech modelů. Může tomu odpovídat například obrázek z fotobanky, tam si ale pořádně prostuduj a dodržuj všechna pravidla a podmínky.`,
}

const userInfo = {
    userName: {
        user: "Zobrazí se u tvých interakcí s výlety a ostatními uživateli (např. hodnocení výletů).",
        author: "Jméno nebo přezdívka, která se zobrazí u tvých výletů, pokud si zvolíš, že chceš být uveden/a jako autor."
    },
    email: "E-mail, pomocí kterého tě můžeme kontaktovat. Slouží i jako login pro přihlášení k tvému účtu.",
    password: "Heslo musí mít nejméně 8 znaků, velké písmeno, malé písmeno, symbol a číslo.",
    firstName: {
        user: "Pokud chceš, můžeš nám dát své křestní jméno. Není to ale povinné.",
        author: "Tvé jméno a příjmení potřebujeme kvůli licenční smlouvě týkající se tvých výletů."
    },
    lastName: {
        user: "Pokud chceš, můžeš nám dát své příjmení. Není to ale povinné.",
        author: "Tvé jméno a příjmení potřebujeme kvůli licenční smlouvě týkající se tvých výletů."
    },
    dateOfBirth: "Tvé datum narození potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    phoneNumber: "Pokud chceš, můžeš uvést telefonní číslo pro efektivnější komunikaci.",
    address: "Tvou adresu potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    aboutAuthor: "Pokud zvolíš, že chceš být uveden/a jako autor výletů, zobrazí se u tvých výletů tvoje přezdívka, fotka a medailonek."
}

export { travelTipInfo, userInfo }