x<template>
  <nav class="profile-nav">
      <ul class="profile-nav__list tr-container">
          <li>
            <router-link to="/myTravelTips">
              <img src="@/assets/svg/icon-my-trips.svg" alt="">{{ $t("general.myTravelTips") }}
              <div v-if="$route.path == '/myTravelTips'" class="active-line"></div>
            </router-link>
          </li>
          <li>
            <router-link to="/messages">
              <div class="icon-message-container">
                <img src="@/assets/svg/icon-messages.svg" alt="">{{$t("general.messages") }}
                <div v-if="unreadMessagesCount != 0" class="unread-conversations-count">{{ unreadMessagesCount }}</div>
              </div>
              <div v-if="$route.path == '/messages'" class="active-line"></div>
            </router-link>
          </li>
          <li>
            <router-link :to="userRole == 'AUTHOR' ? `/admin/manageUser/${userId}` : '/profile'">
              <img src="@/assets/svg/icon-edit-profile.svg" alt="">{{ $t("general.profile") }}
              <div v-if="$route.path == '/profile'" class="active-line"></div>
            </router-link>
          </li>
          <li class="ml-auto">
            <button class="logout" @click="logout">
              <img src="@/assets/svg/icon-arrow-right.svg" alt="">{{ $t("general.logout") }}
            </button>
          </li>
        </ul>
  </nav>
</template>

<script>
import {AUTH_LOGOUT} from "@/store/actions/auth"
import { mapGetters } from 'vuex';

export default {
  name: "PorfileNavBar",

  computed: {
    ...mapGetters({
      userId : 'currentUserId',
      userRole: 'currentUserRole',
      unreadMessagesCount: 'unreadMessagesCount',
    })
  },

  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
      if (this.$route.path != '/') {
        this.$router.push('/');
      }
      this.user = null
    },
  },
}
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

.profile-nav {
  background-color: $tr-white;

  &__list {
    display: flex;
    list-style: none;
    color: $tr-black;
    padding: 0;
  
    li {
      text-align: start;
      
      .icon-message-container {
        position: relative;
        .unread-conversations-count {
          position: absolute;
          left: 13px;
          bottom: 13px;
          width: 20px;
          height: 20px;
          font-size: 12px;
          line-height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $tr-black;
          border-radius: 50%;
          color: $tr-white;
        }

      }

      a, .logout {
        position: relative;
        height: 64px;
        align-items: center;
        padding-block: 0;
        padding-inline: 30px;
        color: $tr-black;
        width: 100%;
        display: flex;
        align-items: center;

        .active-line {
          position: absolute;
          height: 3px;
          width: calc(100% - 54px);
          background-color: $tr-black;
          bottom: 0px;
        }
  
        img {
          margin-right: 20px;
        }
      }
  
      .logout {
        border: none;
        background: none;
  
        &:hover {
          text-decoration: underline;
        }
      }
  
      .arrow-bg {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding-left: 40px;
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="m9 6 6 6-6 6" stroke="%23000"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: right;
      }
    }
  }
  
  @media (max-width: 1050px) {
    a {
      padding-inline: 15px !important;

      .active-line {
        width: calc(100% - 24px) !important;
      }

      img {
        margin-right: 10px !important;
      }
    }
  }

  @media (max-width: 900px) {
    display: none;
  }

}

</style>
